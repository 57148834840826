import React , {useEffect, useContext} from 'react';

import {preloadImages} from './utils/utils';
import {initSmoothScrolling, animateOnScroll} from './utils/animation';

import Item from './utils/Item';
import { ItemContext, ItemProvider} from './utils/ItemContext';

import VideoAndFilter from './Elements/videoAndFilter';
import ThreeScene from './Elements/three_scene';
import Map from './Elements/maps/map';

import './css/App.css';

const App = () => {

  const { items } = useContext(ItemContext);

  useEffect(() => {
    // Preload images
    preloadImages('.item__image-inner').then(() => {
    document.body.classList.remove('loading');

    // document.addEventListener('DOMContentLoaded', () => {
    //   initSmoothScrolling();
    //   animateOnScroll();
    //   initEvents();
    // });
    
    initSmoothScrolling();
    animateOnScroll(items);
    
    });
  }, [items]);

  return (
    <main>
      <div className="heading">
        <h2 className="heading__main">ABUTTING * TOKYO * ABUTTING * TOKYO * ABUTTING * TOKYO * ABUTTING</h2>
        <span className="heading__sub">2024</span>
      </div>

      <VideoAndFilter/>
      <ThreeScene>Your browser does not support the three.js</ThreeScene>
      <div className="content">
      <Item key={99} number={909} title={`hello !`} imageUrl={`${process.env.PUBLIC_URL}/videos/head_left.mp4`} description={`hiiii`}/>
      </div>

      <Map></Map>

      {/* <div className="content">
          {Array.from({ length: 3 }).map((_, index) => {
            // const itemRef = React.createRef();
            const imageSrc = `${process.env.PUBLIC_URL}/images/${index + 1}.jpg`; // 使用 PUBLIC_URL
            const title = `Title ${index + 1}`;
            const description = `Description for item ${index + 1}`;

            return (
              <Item
                key={index + 1}
                number={index + 1}
                title={title}
                imageUrl={imageSrc}
                description={description}
              />
            );
          })}
      </div> */}

      
    </main>
  );
}

const AppWithProviders = () => (
  <ItemProvider>
      <App />
  </ItemProvider>
);

export default AppWithProviders;

